import React from 'react';
import { Link } from 'gatsby';
import { MainLayout } from '../components/Layout';
import AddressSearch from '../components/AddressSearch/AddressSearch';
import {
  randomInt,
  slashPrefix,
  sliceIntoNArrays,
  truncateString,
} from '../utils/utils';
import ZoneMap from '../components/Map/ZoneMap';
import moment from 'moment';

export default ({ location, data, pageContext }) => {
  const zoneData = pageContext.zone;
  const zoneStreets = pageContext.zoneStreets;

  const productCategoryData = pageContext.productCategory;
  const servicesCategories = pageContext.servicesCategories;
  const itemsCategories = pageContext.itemsCategories;
  const marketProducts = pageContext.marketProducts;

  const title =
    productCategoryData.seoTitle +
    ' - ' +
    zoneData.name +
    ' à ' +
    zoneData.region;
  const description =
    'Vous recherchez ' +
    productCategoryData.seoTitle +
    ' dans le quartier ' +
    zoneData.name +
    ' à ' +
    zoneData.region;

  return (
    <MainLayout siteTitle={title} siteDescription={description}>
      <section className="container section-zone">
        <div className="row">
          <div className="col-12 text-align-center">
            <div className="roboto bold mt-10 mt-5-md pl-2-md pr-2-md font-size-xxl font-size-xl-md">
              Vous recherchez{' '}
              <span className="yellowTag">{productCategoryData.seoTitle}</span>{' '}
              dans le quartier{' '}
              <Link
                to={`/quartiers${slashPrefix(zoneData.slug)}/`}
                className="text-decoration-none"
              >
                {zoneData.name}
              </Link>{' '}
              à {zoneData.region} ?
            </div>
          </div>
        </div>

        <div className="row mt-10">
          <div className="col-12 font-size-xl text-align-center bold roboto pl-2-md pr-2-md">
            Rejoignez vos voisins du quartier{' '}
            <Link
              to={`/quartiers${slashPrefix(zoneData.slug)}/`}
              className="text-decoration-none"
            >
              <span className="yellowTag">{zoneData.name}</span>
            </Link>{' '}
            !
          </div>
        </div>

        <div className="row">
          <div className="col-4 hidden-sm"></div>
          <div className="col-4 content col-12-sm pl-2-md pr-2-md">
            <div className="font-size-md text-align-center font-size-sm-md pb-3">
              Saisissez votre adresse postale pour démarrer votre inscription
            </div>
            <AddressSearch
              id="addressSearch"
              zoneId={zoneData.id}
              placeholder="Mon adresse postale"
            />
          </div>
          <div className="col-4 hidden-sm"></div>
        </div>

        <div className="row mt-10 mt-5-md">
          <div className="col-4 hidden-sm"></div>
          <div className="col-4 col-12-sm">
            <div className="zone-map text-align-right text-align-center-md pl-5-md pr-5-md mt-10-md">
              <ZoneMap
                zoneCoordinates={zoneData.location?.coordinates}
                propertyLocation={null}
              />
            </div>
          </div>
          <div className="col-4 hidden-sm"></div>
        </div>

        <div className="row mt-10">
          <div className="pt-2 text-color-purple-grey pl-2-md pr-2-md">
            <div className="font-size-md2 bold pb-1">
              Quartier {zoneData.name} à {zoneData.region}
            </div>
            <hr />

            {sliceIntoNArrays(zoneStreets, 4).map((streets, index) => (
              <div className="col-3 pt-1 font-size-sm">
                <ul className="full-width">
                  {streets.map((value, index) => (
                    <li className="pb-2">
                      <Link to={`${value.slug}/`} activeClassName="bold">
                        <span className="pr-1">
                          {value.streetName +
                            ' à ' +
                            value.city +
                            ' (' +
                            value.postalCode +
                            ')' +
                            (index + 1 < zoneStreets.length ? ',' : '')}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <div className="row mt-10">
          <div className="pt-2 text-color-purple-grey pl-2-md pr-2-md">
            <div className="font-size-md2 bold pb-1">
              Petites annonces dans le quartier {zoneData.name} à{' '}
              {zoneData.region}
            </div>
            <hr />

            {marketProducts && marketProducts.length > 0 && (
              <div className="row mt-10 mt-5-md">
                {marketProducts.map((product) => {
                  let randomIconNumber = randomInt(1, 6);

                  return (
                    <div className="col-3 col-12-sm">
                      <div className="box-shadow ml-4 mr-4 p-4 mb-4">
                        <div className="roboto">
                          <div className="float-left pt-1 mr-2">
                            <img
                              src={`${process.env.GATSBY_STATIC_URL}/avatars/${randomIconNumber}/icon.png`}
                              width={20}
                              height={20}
                            />
                          </div>

                          <div className="bold font-size-md2">
                            {truncateString(product.description, 30)}
                          </div>
                          <div className="font-size-sm">
                            {product.property.publicName}
                          </div>
                        </div>
                        <div className="font-size-xs pt-2">
                          Publié le{' '}
                          {moment(product.createdAt).format('D MMMM YYYY')}
                        </div>
                        <div className="font-size-md mt-4">
                          {truncateString(product.description, 100)}
                        </div>

                        <div className="mt-2 font-size-md text-align-right">
                          <Link
                            to={'/inscription/'}
                            className="yellowTag bold cursor-pointer"
                          >
                            Ça m'intéresse !
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            {sliceIntoNArrays(servicesCategories, 2).map(
              (categories, index) => (
                <div className="col-3 pt-1">
                  <ul className="full-width">
                    {categories.map((value, index) => (
                      <li className="pb-2 pr-2">
                        <div className="font-size-md">
                          <Link
                            to={
                              '/petites-annonces/services' +
                              slashPrefix(value.slug) +
                              slashPrefix(zoneData.slug) +
                              '/'
                            }
                            activeClassName="bold"
                          >
                            Services > {value.name}
                          </Link>
                        </div>
                        <div className="font-size-xs pt-1">
                          <Link
                            to={
                              '/petites-annonces/services' +
                              slashPrefix(value.slug) +
                              slashPrefix(zoneData.slug) +
                              '/'
                            }
                            activeClassName="bold"
                          >
                            {value.seoTitle} dans le quartier {zoneData.name}
                          </Link>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ),
            )}

            {sliceIntoNArrays(itemsCategories, 2).map((categories, index) => (
              <div className="col-3 pt-1">
                <ul className="full-width">
                  {categories.map((value, index) => (
                    <li className="pb-2 pr-2">
                      <div className="font-size-md">
                        <Link
                          to={
                            '/petites-annonces/biens' +
                            slashPrefix(value.slug) +
                            slashPrefix(zoneData.slug) +
                            '/'
                          }
                          activeClassName="bold"
                        >
                          Biens > {value.name}
                        </Link>
                      </div>
                      <div className="font-size-xs pt-1">
                        <Link
                          to={
                            '/petites-annonces/biens' +
                            slashPrefix(value.slug) +
                            slashPrefix(zoneData.slug) +
                            '/'
                          }
                          activeClassName="bold"
                        >
                          {value.seoTitle} dans le quartier {zoneData.name}
                        </Link>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>
    </MainLayout>
  );
};
